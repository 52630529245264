import React from "react";

const OrientationBar = ({ direction, percent }) => {
  const percentVal =
    percent === 20 ? 21 : percent === 50 ? 51 : percent === 80 ? 81 : percent;
  const indicatorLocation = direction
    ? 50 + percentVal / 2
    : 50 - percentVal / 2;

  return (
    <div className="relative w-full lg:mb-6 ">
      <div className="grid h-6 grid-cols-[repeat(20,minmax(0,1fr))] gap-2 max-lg:h-4 max-lg:gap-1">
        <div className={` relative col-span-2 grid grid-cols-2`}>
          <div className="rounded-l-full bg-[#135B91]"></div>
          <div className="bg-[#3469AD]"></div>
          <span className="absolute left-1/2 top-6 -translate-x-1/2 whitespace-nowrap pt-1 text-[13px] font-medium leading-tight text-primary-800 max-lg:top-4 max-lg:text-[9px]">
            Sıra Dışı
          </span>
        </div>
        <div className={` relative col-span-3 grid grid-cols-3`}>
          <div className="bg-[#4771BD]"></div>
          <div className="bg-[#607CD3]"></div>
          <div className="bg-[#7986E8]"></div>
          <span className="absolute left-1/2 top-6 -translate-x-1/2 whitespace-nowrap pt-1 text-[13px] font-medium leading-tight text-primary-800 max-lg:top-4 max-lg:text-[9px]">
            Güçlü
          </span>
        </div>
        <div className={` relative col-span-3 grid grid-cols-3`}>
          <div className="bg-[#8D94F8]"></div>
          <div className="bg-[#86A9F0]"></div>
          <div className="bg-[#82B7EA]"></div>
          <span className="absolute left-1/2 top-6 -translate-x-1/2 whitespace-nowrap pt-1 text-[13px] font-medium leading-tight text-primary-800 max-lg:top-4 max-lg:text-[9px]">
            Dengeli
          </span>
        </div>
        <div className={` relative col-span-4 grid grid-cols-4`}>
          <div className="bg-[#7CCBE3]"></div>
          <div className="bg-[#77DEDB]"></div>
          <div className="bg-[#77DEDB]"></div>
          <div className="bg-[#7CCBE3]"></div>
          <span className="absolute left-1/2 top-6 -translate-x-1/2 whitespace-nowrap pt-1 text-[13px] font-medium leading-tight text-primary-800 max-lg:top-4 max-lg:text-[9px]">
            Tam Dengeli
          </span>
        </div>
        {/* <div
          className={`${

              ? "opacity-100"
              : "opacity-30"
          } relative grid max-lg:row-span-2 max-lg:grid-rows-2 lg:col-span-2 lg:grid-cols-2`}
        >

          <span className="absolute left-1/2 top-6 -translate-x-1/2 whitespace-nowrap pt-1 text-[13px] leading-tight text-primary-800">
            Tam Dengeli
          </span>
        </div> */}
        <div className={` relative col-span-3 grid grid-cols-3`}>
          <div className="bg-[#82B7EA]"></div>
          <div className="bg-[#86A9F0]"></div>
          <div className="bg-[#8D94F8]"></div>
          <span className="absolute left-1/2 top-6 -translate-x-1/2 whitespace-nowrap pt-1 text-[13px] font-medium leading-tight text-primary-800 max-lg:top-4 max-lg:text-[9px]">
            Dengeli
          </span>
        </div>
        <div className={` relative col-span-3 grid grid-cols-3`}>
          <div className="bg-[#7986E8]"></div>
          <div className="bg-[#607CD3]"></div>
          <div className="bg-[#4771BD]"></div>
          <span className="absolute left-1/2 top-6 -translate-x-1/2 whitespace-nowrap pt-1 text-[13px] font-medium leading-tight text-primary-800 max-lg:top-4 max-lg:text-[9px]">
            Güçlü
          </span>
        </div>
        <div className={` relative col-span-2 grid grid-cols-2`}>
          <div className="bg-[#3469AD]"></div>
          <div className="rounded-r-full  bg-[#135B91]"></div>
          <span className="absolute left-1/2 top-6 -translate-x-1/2 whitespace-nowrap pt-1 text-[13px] font-medium leading-tight text-primary-800 max-lg:top-4 max-lg:text-[9px]">
            Sıra Dışı
          </span>
        </div>
      </div>

      <div
        className={`absolute -left-[17px] -top-3.5  z-10 w-full max-lg:-left-[10px]`}
        style={{ paddingLeft: `${indicatorLocation}%` }}
      >
        {/* INDICATOR */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="33"
          height="24"
          viewBox="0 0 33 24"
          fill="none"
          className="max-lg:w-5"
        >
          <g filter="url(#filter0_d_5473_28772)">
            <path
              d="M16.707 3.45312H28.0642C29.7667 3.45312 30.6907 5.44468 29.5914 6.74461L18.2341 20.1738C17.4352 21.1185 15.9789 21.1185 15.1799 20.1738L3.82271 6.74461C2.72334 5.44468 3.64733 3.45312 5.34981 3.45312H16.707Z"
              fill="white"
            />
            <path
              d="M16.707 3.45312H28.0642C29.7667 3.45312 30.6907 5.44468 29.5914 6.74461L18.2341 20.1738C17.4352 21.1185 15.9789 21.1185 15.1799 20.1738L3.82271 6.74461C2.72334 5.44468 3.64733 3.45312 5.34981 3.45312H16.707Z"
              stroke="#6070CF"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_5473_28772"
              x="0.845703"
              y="0.953125"
              width="31.7227"
              height="22.4292"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="1" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.343766 0 0 0 0 0.343766 0 0 0 0 0.343766 0 0 0 0.12 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_5473_28772"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_5473_28772"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>

      <div className="absolute right-1/2 top-1/2 h-4 w-4 -translate-y-1/2 translate-x-1/2 rotate-45 rounded bg-neutral-100 max-lg:h-3 max-lg:w-3 max-lg:rounded-[2px]"></div>
    </div>
  );
};

export default OrientationBar;
