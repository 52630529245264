import React from "react";
import { twMerge } from "tailwind-merge";

export const TagWithIcon = ({
  onClick,
  text,
  icon,
  icon2,
  className,
  props,
}) => {
  const classes =
    "flex flex-row justify-center gap-1 items-center py-1.5 px-3 font-medium text-[13px] leading-4 bg-primary-600 text-primary-100 rounded-3xl";

  const mergedClasses = twMerge(classes, className);
  return (
    <div className={mergedClasses} onClick={onClick} {...props}>
      {icon}
      <p className="text-center">{text}</p>
      {icon2}
    </div>
  );
};
