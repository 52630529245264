import { Link, useLocation, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useStore } from "../../../../store/useStore";
import {
  BagIconFilled,
  HeartFilled,
  RightArrowOutlined,
  StarOutlined,
} from "../../../OldDesign/Icons";
import { TagWithIcon } from "../../Tags";
import Button from "../../Button/Button";
import IsMobile from "../../../../utils/IsMobile";
const HeroRecruiter = () => {
  const content = useStore(
    (state) =>
      state.content.data["Dashboard"].content.translations[
        "Recruiter Info Boxes"
      ],
  );
  const navigate = useNavigate();
  const location = useLocation();
  const welcomeMessage = useStore(
    (state) => state.dashboard.data.welcomeMessage,
  );
  const welcomeTitle = useStore((state) => state.dashboard.data.welcomeTitle);
  const badgeMessages = useStore((state) => state.dashboard.data.badgeMessages);
  const userPicture = useStore((state) => state.user.data.userPicture);
  // console.log("dashboard content", content);
  return (
    <div className="max-lg:pt-[55px]">
      <div className="container2 flex flex-col gap-6 rounded-xl bg-[linear-gradient(273deg,#4E31AA_-3.14%,#106BAC_98.79%)] px-9 py-8 font-inter text-neutral-100 max-lg:mb-6 max-lg:justify-center max-lg:gap-3 max-lg:p-4">
        <div className="flex flex-row items-center gap-4 py-3 max-lg:gap-2 max-lg:py-0">
          <img
            src={userPicture}
            className="h-[100px] w-[100px] rounded-full max-lg:h-[77px] max-lg:w-[77px]"
            alt="Profile"
          />
          <div className="flex w-full flex-col justify-center gap-2 max-lg:gap-1">
            <div className="flex justify-between">
              <p className="text-[32px] font-bold leading-tight max-lg:text-[18px]">
                {welcomeTitle}
              </p>
              <div className="flex items-center gap-4 max-lg:hidden">
                <TagWithIcon
                  icon={<BagIconFilled className="h-[14px] w-[14px]" />}
                  text={badgeMessages[0]}
                  className="bg-neutral-100 text-primary-600"
                />
                <TagWithIcon
                  icon={<HeartFilled className="h-[14px] w-[14px]" />}
                  text={badgeMessages[1]}
                  className="bg-neutral-100 text-primary-600"
                />
              </div>
            </div>
            <p className="text-justify text-[22px] font-medium leading-tight max-lg:text-left max-lg:text-[13px] max-lg:font-normal">
              {welcomeMessage}
            </p>
          </div>
        </div>
        <div className="flex items-center gap-3 pb-2 lg:hidden">
          <TagWithIcon
            icon={<BagIconFilled className="h-[14px] w-[14px]" />}
            text={badgeMessages[0]}
            className="border bg-opacity-0 py-1 text-neutral-100"
          />
          <TagWithIcon
            icon={<HeartFilled className="h-[14px] w-[14px]" />}
            text={badgeMessages[1]}
            className="border bg-opacity-0 py-1 text-neutral-100"
          />
        </div>
        <div className="flex gap-6 max-lg:flex-col max-lg:gap-4">
          <div className="flex basis-1/2 flex-col justify-between rounded-xl bg-[linear-gradient(77deg,#060B28_-2.59%,rgba(10,14,35,0.28)_99.98%)] px-4 py-6 max-lg:invisible max-lg:p-0">
            <h3 className="pb-4 text-[17px] font-semibold leading-tight max-lg:hidden">
              {content["aday ekle 2 baslik"]}
            </h3>
            <div className="flex flex-row gap-[5px] pb-6 max-lg:hidden">
              <StarOutlined className="h-[13px] w-[13px]" />
              <p className="text-[15px] leading-tight">
                {content["aday ekle 2 aciklama"]}
              </p>
            </div>
            <div>
              <HashLink smooth to="/mynetwork/connections">
                <Button
                  className="border-neutral-100 bg-neutral-100 text-primary-900 max-lg:visible max-lg:w-full"
                  icon2={
                    <RightArrowOutlined className="h-5 w-5 max-lg:hidden" />
                  }
                >
                  {IsMobile()
                    ? content["aday ekle 2 baslik"]
                    : content["aday ekle 2 buton"]}
                </Button>
              </HashLink>
            </div>
          </div>
          <div className="flex basis-1/2 flex-col justify-between rounded-xl bg-[rgba(255,255,255,0.15)] px-4 py-6 max-lg:invisible max-lg:p-0">
            <h3 className="pb-4 text-[17px] font-semibold leading-tight max-lg:hidden">
              İhtiyaçlarınızı Gözden Geçirin
            </h3>
            <div className="flex flex-row gap-[5px] pb-6 max-lg:hidden">
              <StarOutlined className="h-[13px] w-[13px]" />
              <p className="text-[15px] leading-tight">
                Humanas'ın akıllı algoritmaları sayesinde çalışan, aday ve
                şirketinize yakından bakın; adanmışlık, ikna, yönetim, çeviklik
                vb verimlilik skorları üzerinden tavsiyeler alın.
              </p>
            </div>
            <div>
              <Link
                to="/aid"
                // state={{
                //   isModalOpen: true,
                // }}
              >
                <Button
                  className="border-neutral-100 bg-neutral-100 text-primary-900 max-lg:visible max-lg:w-full max-lg:border "
                  icon2={
                    <RightArrowOutlined className="h-5 w-5 max-lg:hidden" />
                  }
                  onClick={() => navigate("/aid")}
                >
                  {IsMobile()
                    ? "İhtiyaçlarınızı Gözden Geçirin!"
                    : content["aday ekle 2 buton"]}
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroRecruiter;
