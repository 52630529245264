import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

const Modal = ({ showModal, setShowModal, children, previousLocation }) => {
  const navigate = useNavigate();
  const modalRef = useRef();
  useEffect(() => {
    const observerRefVal = modalRef.current;
    if (observerRefVal) disableBodyScroll(observerRefVal);
    return () => {
      if (observerRefVal) {
        enableBodyScroll(observerRefVal);
      }
    };
  }, []);
  return (
    <>
      {showModal ? (
        <div ref={modalRef} className="fixed inset-0 z-50 overflow-y-auto">
          <div
            className="fixed inset-0 h-full w-full bg-black opacity-50"
            onClick={() => {
              setShowModal
                ? setShowModal(false)
                : navigate(previousLocation || -1);
            }}
          ></div>
          <div className="flex min-h-screen items-center">
            <div className="relative mx-auto my-[4%] overflow-hidden rounded-[10px] bg-white shadow-lg max-lg:max-w-[95%]">
              {children}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Modal;
