import React, { Fragment } from "react";
import SectionTitle from "../SectionTitle";
import OrientationSummary from "../OrientationSummary";
import { Wordcloud } from "@visx/wordcloud";
import Tooltip from "../Tooltips/Tooltip";

const SummarySection = ({ user }) => {
  function showTooltip(evt, text) {
    let tooltip = document.getElementById("tooltip");
    tooltip.innerHTML = text;
    tooltip.style.display = "block";
    tooltip.style.left =
      evt.clientX > 150 ? evt.clientX - 130 + "px" : evt.clientX - 20 + "px";
    tooltip.style.top = evt.clientY + 10 + "px";
  }

  function hideTooltip() {
    var tooltip = document.getElementById("tooltip");
    tooltip.style.display = "none";
  }
  return (
    <div className=" grid grid-cols-2 gap-4">
      <div className="flex flex-col">
        <SectionTitle
          title={"Kişilik Tipi Özeti"}
          className="items-start gap-1 pb-2 text-[22px]"
        />
        <div className="flex-1 rounded-xl border border-neutral-300 bg-neutral-100">
          <div className="mx-auto">
            <div
              id="tooltip"
              display="none"
              className="fixed z-[999] hidden max-w-[360px] rounded-lg bg-primary-800 p-2 text-justify text-sm text-neutral-100"
            ></div>
            <Wordcloud
              words={user.metacv.word_cloud}
              fontSize={(word) => Math.sqrt(word.value)}
              padding={8}
              font={"Inter"}
              rotate={(word) => 0}
              spiral="archimedean"
              width={574}
              height={390}
              random={() => 0.5}
            >
              {(cloudWords) =>
                cloudWords.map((word, index) => {
                  return (
                    <Fragment key={index}>
                      <defs key={index}>
                        <filter
                          x="-4%"
                          y="-5%"
                          width="108%"
                          height="110%"
                          id={index}
                        >
                          <feFlood floodColor={word.bgColor} />
                          <feGaussianBlur stdDeviation="2" />
                          <feComponentTransfer>
                            <feFuncA type="table" tableValues="0 0 0 1" />
                          </feComponentTransfer>

                          <feComponentTransfer>
                            <feFuncA
                              type="table"
                              tableValues="0 1 1 1 1 1 1 1"
                            />
                          </feComponentTransfer>
                          <feComposite operator="over" in="SourceGraphic" />
                        </filter>
                      </defs>
                      <text
                        style={{
                          fontSize: word.size,
                          fontWeight: Math.log(word.value) * 100,
                          fill: word.color,
                          fontFamily: word.font,
                          cursor: "help",
                        }}
                        filter={`url(#${index})`}
                        textAnchor="middle"
                        transform={`translate(${word.x}, ${word.y}) rotate(${word.rotate})`}
                        onMouseMove={(e) =>
                          showTooltip(e, word.explain.description)
                        }
                        onMouseLeave={hideTooltip}
                      >
                        {word.text}
                      </text>
                    </Fragment>
                  );
                })
              }
            </Wordcloud>
            <div className="flex flex-wrap justify-center gap-4">
              {user.metacv.upper_dimensions.map((upType, index) => {
                return (
                  <Tooltip key={index} content={upType.description}>
                    <div className="flex cursor-help items-center justify-center gap-1">
                      <span
                        className={`text-sm`}
                        style={{ color: upType.color }}
                      >
                        {"\u2B24"}
                      </span>
                      <p className="text-sm">{upType.name}</p>
                    </div>
                  </Tooltip>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <SectionTitle
          title={"Mesleki Yönelim Özeti"}
          className="items-start gap-1 pb-2 text-[22px]"
        />
        <div className="flex-1 rounded-xl border border-neutral-300 bg-neutral-100 p-6">
          <OrientationSummary metacv={user.metacv} />
        </div>
      </div>
    </div>
  );
};

export default SummarySection;
