import { forwardRef } from "react";
import { PhoneInput } from "react-international-phone";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import VerificationInput from "react-verification-input";

export const TextArea = forwardRef((props, ref) => {
  return (
    <textarea
      ref={ref}
      {...props}
      className="border-1 focus:border-1 min-h-[48px] w-full rounded-lg border border-neutral-300 bg-neutral-100 px-4 py-4 text-[14px] hover:border-primary-600 focus:border-primary-600 lg:min-h-[54px] lg:text-[16px]"
    />
  );
});

export const Input = forwardRef((props, ref) => {
  return (
    <input
      autoFocus={false}
      ref={ref}
      className="border-1 focus:border-1 h-[48px] w-full rounded-lg border border-neutral-300 bg-neutral-100 py-4 pl-4 pr-10 text-[14px] hover:border-primary-600 focus:border-primary-600 lg:h-[54px] lg:text-[16px]"
      {...props}
    />
  );
});

export const Checkbox = forwardRef((props, ref) => {
  return (
    <div className="relative h-5 w-5">
      <input
        ref={ref}
        type="checkbox"
        className={`input-checkbox peer absolute h-0 w-0 cursor-pointer opacity-0`}
        {...props}
      />
      <span
        className={`input-checkmark absolute left-0 top-0 h-5 w-5 rounded-[4px] border border-neutral-400 bg-white peer-checked:border-[#106BAC] peer-checked:bg-[#106BAC]`}
      ></span>
    </div>
  );
});
export const Radio = forwardRef((props, ref) => {
  return (
    <div className="relative h-5 w-5">
      <input
        ref={ref}
        type="radio"
        className={`peer absolute h-0 w-0 cursor-pointer opacity-0`}
        {...props}
      />
      <span
        className={`absolute left-0 top-0 h-5 w-5 rounded-full border border-neutral-400 bg-white peer-checked:border-[#106BAC] peer-checked:ring-4 peer-checked:ring-inset peer-checked:ring-[#106BAC]`}
      ></span>
    </div>
  );
});
export const Phone = forwardRef((props, ref) => {
  return (
    <PhoneInput
      defaultCountry="tr"
      disableFormatting
      forceDialCode
      inputClassName="border"
      className="border-1 rounded-lg border border-neutral-300  hover:border-primary-600 focus:border-4 focus:border-primary-600"
      inputProps={{
        className:
          "pl-3 h-[49px] bg-neutral-100 text-[15px] lg:h-[52px] lg:text-[16px] outline-none rounded-lg",
      }}
      countrySelectorStyleProps={{
        dropdownStyleProps: {
          style: { maxHeight: "120px", top: "60px" },
        },
        className: "bg-neutral-200 rounded-l-lg",
        buttonClassName:
          "border-none h-full rounded-l-lg hover:rounded-l-lg px-3",
      }}
      ref={ref}
      {...props}
    />
  );
});
export const ASelect = forwardRef((props, ref) => {
  return (
    <AsyncSelect
      cacheOptions={true}
      ref={ref}
      isClearable
      styles={{
        multiValue: (styles, state) => ({
          ...styles,
          backgroundColor: "#106BAC",
          borderRadius: "12px",
          maxWidth: "200px",
          color: "#FFFFFF",
          ":hover": "#FFFFFF",
        }),
        multiValueLabel: (styles, state) => ({
          ...styles,
          color: "#FFFFFF",
        }),
        placeholder: (styles, state) => ({
          ...styles,
          color: "#9A9ABA",
        }),
        control: (styles, state) => ({
          ...styles,
          borderRadius: "8px",
          borderColor: state.isFocused ? "#106BAC" : "#DFDFEE",
          borderWidth: "1px",
          boxShadow: "none",
        }),
        valueContainer: (styles, state) => ({
          ...styles,
          minHeight: "50px",
          maxHeight: "130px",
          paddingLeft: "16px",
          textOverflow: "ellipsis",
          wordBreak: "break-word",
          overflowY: "scroll",
        }),
      }}
      {...props}
    />
  );
});
export const SSelect = forwardRef((props, ref) => {
  return (
    <Select
      ref={ref}
      styles={{
        placeholder: (styles, state) => ({
          ...styles,
          color: "#9A9ABA",
        }),
        control: (styles, state) => ({
          ...styles,
          borderRadius: "8px",
          borderColor: state.isFocused ? "#106BAC" : "#DFDFEE",
          borderWidth: "1px",
          boxShadow: "none",
        }),
        valueContainer: (styles, state) => ({
          ...styles,
          height: "50px",
          paddingLeft: "16px",
        }),
      }}
      {...props}
    />
  );
});

export const Switch = forwardRef((props, ref) => {
  return (
    <input
      className="h-9 w-16 appearance-none rounded-full bg-neutral-300 before:pointer-events-none before:absolute before:h-9 before:w-16 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:ml-0.5 after:mt-0.5 after:h-8 after:w-8 after:rounded-full after:border-none after:bg-neutral-100 after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-success-400 checked:after:absolute checked:after:z-[2] checked:after:ml-[30px] checked:after:h-8 checked:after:w-8 checked:after:rounded-full checked:after:border-none checked:after:bg-neutral-100 checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-8  focus:after:w-8 focus:after:rounded-full focus:after:content-[''] checked:focus:border-success-400 checked:focus:bg-success-400 checked:focus:before:ml-0.5 max-lg:h-8 max-lg:w-[52px] max-lg:before:h-8 max-lg:before:w-[52px] max-lg:after:h-7 max-lg:after:w-7 max-lg:checked:after:ml-[22px] max-lg:checked:after:h-7 max-lg:checked:after:w-7 max-lg:focus:after:h-7 max-lg:focus:after:w-7"
      role="switch"
      type="checkbox"
      ref={ref}
      {...props}
    />
  );
});

export const CodeBox = forwardRef((props, ref) => {
  return (
    <VerificationInput
      ref={ref}
      placeholder=""
      validChars="0-9"
      {...props}
      classNames={{
        container: "w-[310px] h-[46px] lg:w-[400px] lg:h-[60px]",
        character: `${
          props.error
            ? "text-error-400 border-error-400"
            : "text-primary-600 border-primary-200"
        } bg-neutral-100 rounded-md outline-none text-[36px] font-bold flex items-center justify-center`,
        characterSelected: `character-selected-verification-input ${
          props.error ? "border-error-400" : "border-primary-600"
        }`,
        characterInactive: ` ${
          props.error ? "border-error-400" : "border-primary-200"
        }`,
      }}
    />
  );
});
