import React from "react";
import SectionTitle from "./SectionTitle";
import OrientationCard from "./OrientationCard";
import Button from "./Button/Button";
import { RightArrowOutlined } from "../OldDesign/Icons";
import { useStore } from "../../store/useStore";
import DiamondCard from "./Cards/DiamondCard";

const ProfessionalOrientation = () => {
  const content = useStore(
    (state) =>
      state.content.data["Overview"].content.translations[
        "Candidate Mesleki Yonelim Alani"
      ],
  );
  const dimensions = useStore((state) => state.overview.data.metacv.dimensions);
  const polars = useStore((state) => state.overview.data.metacv.polars);
  return (
    <section className="container2 flex flex-col gap-3 py-9 max-lg:py-3">
      <div className="px-9 py-3 max-lg:px-0 max-lg:py-2">
        <SectionTitle
          title={content["mesleki yonelim baslik"]}
          className="pb-3 max-lg:pb-2 lg:items-start"
        />
        <div className="flex flex-col justify-center gap-4 max-lg:px-0">
          <p className="text-[17px] font-medium leading-tight max-lg:text-center max-lg:text-[13px] max-lg:font-normal">
            Aşağıda mesleki yönelimlerinize ait sonuçları bulabilirsiniz.
            Sonuçlarınız, 14 Mesleki yönelim alanı üzerinde, 2 kutuplu olacak
            şekilde değerlendirildi. Humanas algoritmaları her bir yönelimde
            size ait bir skor oluşturur; söz konusu skor Humanas topluluğu
            içerisindeki Meta CV sahiplerine oranlanır. Ölçümlerin detayına ve
            sonuçların ne anlama geldiğine yakından bakın.
          </p>
          <div className="flex flex-row items-center justify-between max-lg:hidden">
            <p className="text-[17px] font-medium leading-tight max-lg:text-[13px] max-lg:font-normal">
              Unutmayın! Yolculuğunuzda sizi daha fazla tanıyor; dolayısıyla
              yönelimlerinize dair daha doğru bilgilere ulaşıyoruz.
              <br />
            </p>
            <Button
              className="relative text-neutral-100"
              disabled={true}
              icon2={<RightArrowOutlined className="h-5 w-5" />}
            >
              {content["mesleki yonelim buton"]}
              <span className="absolute bottom-1 left-1 text-[13px] italic opacity-40">
                Coming Soon...
              </span>
            </Button>
          </div>
        </div>
      </div>
      {/* DIAMOND CARDS */}
      {/* <div className="lg:my-12 lg:py-20">
        <div className="flex items-center justify-center max-lg:flex-col max-lg:gap-4 max-lg:pb-4 lg:flex-wrap">
          <DiamondCard polar={polars[0]} />
          <DiamondCard polar={polars[1]} />
          <DiamondCard polar={polars[2]} />
        </div>
        <div className="flex items-center justify-center max-lg:flex-col max-lg:gap-4 lg:flex-wrap">
          <DiamondCard polar={polars[3]} />
          <DiamondCard polar={polars[4]} />
        </div>
      </div> */}

      <div className="flex flex-col gap-6">
        {dimensions.map((orientation) => {
          return (
            <OrientationCard
              key={orientation.title}
              orientation={orientation}
            />
          );
        })}
      </div>
    </section>
  );
};

export default ProfessionalOrientation;
