import { sendApiRequest, sendParallelApiRequests } from "./request_sender";

export const checkUser = (
  formData,
  onSuccess = null,
  onError = null,
  onLoading = null,
) => {
  const config = { requestType: "loginCheck", payload: formData };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};
export const loginWithToken = (
  onSuccess = null,
  onError = null,
  onLoading = null,
) => {
  const config = {
    requestType: "loginWithToken",
  };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};
export const loginState = (
  stateData,
  onSuccess = null,
  onError = null,
  onLoading = null,
) => {
  const config = { requestType: "loginState", payload: stateData };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};
export const loginCompany = (
  formData,
  onSuccess = null,
  onError = null,
  onLoading = null,
) => {
  const config = {
    requestType: "loginCompany",
    payload: formData,
  };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};
export const getOverviewContent = (
  onSuccess = null,
  onError = null,
  onLoading = null,
) => {
  const config = { requestType: "getOverviewContent" };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};
export const getDashboardContent = (
  params,
  onSuccess = null,
  onError = null,
  onLoading = null,
) => {
  const config = { params: params, requestType: "getDashboardContent" };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};
export const getCompanyInfo = (
  onSuccess = null,
  onError = null,
  onLoading = null,
) => {
  const config = { requestType: "getCompanyInfo" };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};
// PARALLEL API REQUEST EXAMPLE (NOT USED)
export const getAllCvFields = (
  onSuccess = null,
  onError = null,
  onLoading = null,
) => {
  const config = [
    { requestType: "getLocationFields" },
    { requestType: "getOperationFields" },
    { requestType: "getEducationFields" },
    { requestType: "getWorkingStatusFields" },
    { requestType: "getProfessionalOrderFields" },
    { requestType: "getLanguageFields" },
  ];
  return sendParallelApiRequests(config, onSuccess, onError, onLoading);
};
export const createCompany = (
  formData,
  onSuccess = null,
  onError = null,
  onLoading = null,
) => {
  const config = { requestType: "createCompany", payload: formData };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};
export const confirmAccount = (
  formData,
  onSuccess = null,
  onError = null,
  onLoading = null,
) => {
  const config = { requestType: "confirmAccount", payload: formData };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};
export const twoFactorAuth = (
  formData,
  onSuccess = null,
  onError = null,
  onLoading = null,
) => {
  const config = { requestType: "twoFactorAuth", payload: formData };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};
export const resetPasswordRequest = (
  formData,
  onSuccess = null,
  onError = null,
  onLoading = null,
) => {
  const config = {
    requestType: "resetPasswordRequest",
    params: { user: formData.user },
  };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};
export const resetPasswordSubmit = (
  formData,
  onSuccess = null,
  onError = null,
  onLoading = null,
) => {
  const config = {
    requestType: "resetPasswordSubmit",
    payload: formData,
  };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};
export const updateApplicationForm = (
  formData,
  onSuccess = null,
  onError = null,
  onLoading = null,
) => {
  const config = {
    requestType: "updateApplicationForm",
    payload: formData,
  };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};
export const getCoreCV = (
  onSuccess = null,
  onError = null,
  onLoading = null,
) => {
  const config = {
    requestType: "getCoreCV",
  };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};
export const coreCVPhoneConfirmation = (
  formData,
  onSuccess = null,
  onError = null,
  onLoading = null,
) => {
  const config = {
    requestType: "coreCVPhoneConfirmation",
    payload: formData,
  };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};
export const getNetworkList = (
  payload,
  onSuccess = null,
  onError = null,
  onLoading = null,
) => {
  const config = {
    requestType: "myNetworkList",
    payload: payload,
  };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};
export const companyInvite = (
  payload,
  onSuccess = null,
  onError = null,
  onLoading = null,
) => {
  const config = {
    requestType: "companyInvite",
    payload: payload,
  };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};
export const resendCompanyInvite = (
  params,
  onSuccess = null,
  onError = null,
  onLoading = null,
) => {
  const config = {
    requestType: "resendCompanyInvite",
    params: params,
  };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};
export const metaCvActions = (
  payload,
  onSuccess = null,
  onError = null,
  onLoading = null,
) => {
  const config = {
    requestType: "metaCvActions",
    payload: payload,
  };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};
export const sendSupportTicket = (
  payload,
  onSuccess = null,
  onError = null,
  onLoading = null,
) => {
  const config = {
    requestType: "sendSupportTicket",
    payload: payload,
  };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};
export const getCommunityCandidate = (
  payload,
  onSuccess = null,
  onError = null,
  onLoading = null,
) => {
  const config = {
    requestType: "getCommunityCandidate",
    payload: payload,
  };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};
export const getContent = (
  params,
  onSuccess = null,
  onError = null,
  onLoading = null,
) => {
  const config = {
    requestType: "getContent",
    params: params,
  };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};
export const getStrapiContent = (
  params,
  extendUrl,
  onSuccess = null,
  onError = null,
  onLoading = null,
) => {
  const config = {
    requestType: "getStrapiContent",
    params: params,
    extendUrl: extendUrl,
  };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};
export const deleteInvite = (
  params,
  onSuccess = null,
  onError = null,
  onLoading = null,
) => {
  const config = {
    requestType: "deleteInvite",
    params: params,
  };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};
export const groupActions = (
  payload,
  onSuccess = null,
  onError = null,
  onLoading = null,
) => {
  const config = {
    requestType: "groupActions",
    payload: payload,
  };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};

export const getGroupList = (
  onSuccess = null,
  onError = null,
  onLoading = null,
) => {
  const config = {
    requestType: "getGroupList",
  };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};

export const publicInvite = (
  params,
  onSuccess = null,
  onError = null,
  onLoading = null,
) => {
  const config = {
    params: params,
    requestType: "publicInvite",
  };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};
export const reportActions = (
  payload,
  onSuccess = null,
  onError = null,
  onLoading = null,
) => {
  const config = {
    payload: payload,
    requestType: "reportActions",
  };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};
export const getAllReports = (
  onSuccess = null,
  onError = null,
  onLoading = null,
) => {
  const config = {
    requestType: "getAllReports",
  };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};
export const getSettings = (
  onSuccess = null,
  onError = null,
  onLoading = null,
) => {
  const config = {
    requestType: "getSettings",
  };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};
export const setSettings = (
  payload,
  onSuccess = null,
  onError = null,
  onLoading = null,
) => {
  const config = {
    payload,
    requestType: "setSettings",
    headers: {
      "Content-type": "multipart/form-data",
    },
  };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};
export const publicMetacvView = (
  params,
  onSuccess = null,
  onError = null,
  onLoading = null,
) => {
  const config = {
    params,
    requestType: "publicMetacvView",
  };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};
export const getAid = (onSuccess = null, onError = null, onLoading = null) => {
  const config = {
    requestType: "getAid",
  };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};
export const downloadMetaCv = (
  params,
  onSuccess = null,
  onError = null,
  onLoading = null,
) => {
  const config = {
    params,
    requestType: "downloadMetaCv",
    responseType: "blob",
  };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};
export const getCityOptions = (
  params,
  onSuccess = null,
  onError = null,
  onLoading = null,
) => {
  const config = {
    params,
    requestType: "getCityOptions",
  };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};
export const getSectorOptions = (
  params,
  onSuccess = null,
  onError = null,
  onLoading = null,
) => {
  const config = {
    params,
    requestType: "getSectorOptions",
  };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};
export const getCountryOptions = (
  params,
  onSuccess = null,
  onError = null,
  onLoading = null,
) => {
  const config = {
    params,
    requestType: "getCountryOptions",
  };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};
export const getStateOptions = (
  params,
  onSuccess = null,
  onError = null,
  onLoading = null,
) => {
  const config = {
    params,
    requestType: "getStateOptions",
  };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};
export const setTwoFA = (
  payload,
  onSuccess = null,
  onError = null,
  onLoading = null,
) => {
  const config = {
    payload,
    requestType: "setTwoFA",
  };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};
export const uploadInviteList = (
  payload,
  onSuccess = null,
  onError = null,
  onLoading = null,
) => {
  const config = {
    payload,
    requestType: "uploadInviteList",
    headers: {
      "Content-type": "multipart/form-data",
    },
  };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};
export const downloadInvalidInviteList = (
  payload,
  onSuccess = null,
  onError = null,
  onLoading = null,
) => {
  const config = {
    payload,
    requestType: "uploadInviteList",
    headers: {
      "Content-type": "multipart/form-data",
    },
    responseType: "blob",
  };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};
