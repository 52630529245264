import React, { useEffect, useState } from "react";
import { HumanasLogo } from "../../components/OldDesign/Icons";
import {
  DownloadIllustration,
  SharePageRightCorner,
  SyncIllustration,
} from "../../components/NewDesign/Illustrations";
import { QRCodeSVG } from "qrcode.react";
import { publicInvite } from "../../api/endpoints";
import { useLocation, useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Button from "../../components/NewDesign/Button/Button";
import mockups from "../../assets/shareMockup.png";
import IsMobile from "../../utils/IsMobile";

const SharePage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { state } = useLocation();
  const [campaign, setCampaign] = useState({
    campaignData: null,
    isLoaded: false,
  });
  const url = window.location.pathname + window.location.search;
  // console.log("url", url);
  const id = searchParams.get("id");
  // console.log("id", id);
  const handleCopyUniqueId = async () => {
    const clippedText = `mylink?qr_code;${campaign.campaignData.unique_id}`;
    await navigator.clipboard
      .writeText(clippedText)
      .then(() =>
        window.open("https://humanas.io/LazyGuruStore.php", "_blank"),
      );
  };

  useEffect(() => {
    const params = { campaign_name: id };
    publicInvite(params, (res) => {
      setCampaign({ campaignData: res.data, isLoaded: true });
      localStorage.setItem("autoLoginLink", url);
    });
  }, [id]);
  if (!campaign.isLoaded) {
    return null;
  }
  return (
    <div
      className={`mx-auto bg-[url("/src/assets/bg/bluewaves.svg")] bg-[position:top_right] bg-no-repeat max-lg:bg-[position:top_right] max-lg:[position:top_center] lg:max-w-[1440px]`}
    >
      <div className={`container2 p-9 pt-16 max-lg:px-0 max-lg:py-3`}>
        <div className="grid grid-cols-5 pb-4 max-lg:grid-cols-1">
          <div className="col-span-2">
            <HumanasLogo className="text-primary-600 max-lg:mx-auto max-lg:text-neutral-100" />
            <h5 className="pb-2 pt-12 text-[18px] font-bold leading-tight text-primary-800 max-lg:pt-3 max-lg:text-center max-lg:text-[16px] max-lg:text-neutral-100">
              Merhaba
            </h5>
            <p className="mx-auto pb-3 text-[32px] font-semibold leading-tight text-primary-800 max-lg:max-w-[600px] max-lg:text-center max-lg:text-[18px] max-lg:text-neutral-100">
              {campaign.campaignData.company_name} şirketinden{" "}
              {campaign.campaignData.inviter_name} size Meta CV’nizi
              görüntülemek için bir istek gönderdi.
            </p>
          </div>
          <div className="col-span-3 flex items-center justify-center">
            <p className="pl-4 text-right  text-[22px] leading-tight text-neutral-100 max-lg:w-[120px] max-lg:self-end max-lg:p-0 max-lg:text-[14px] lg:self-start">
              <span className="font-bold">Lazy Guru</span> Oyunlaştırılmış
              kariyer uygulaması
            </p>
            <img src={mockups} alt="share" className="max-lg:w-[160px]" />
            <p className="text-[22px] leading-tight  text-neutral-100 max-lg:w-[120px] max-lg:self-end max-lg:p-0 max-lg:text-[14px]">
              <span className="font-bold">Meta CV</span> Potansiyel odaklı yeni
              nesil CV!
            </p>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center gap-9 py-14 max-lg:gap-3 max-lg:py-4">
          {IsMobile() ? (
            <>
              {/* DOWNLOAD CARD */}
              {!state?.linkFound && (
                <div className="flex flex-col rounded-xl bg-primary-75 p-9 max-lg:p-4">
                  <div className="mb-9  flex items-center gap-3 border-b border-neutral-300 pb-9 max-lg:mb-4 max-lg:pb-2">
                    <span className="flex h-10 w-10 items-center justify-center rounded-full bg-primary-700 p-3.5 text-[26px] font-bold leading-tight text-neutral-100 max-lg:h-7 max-lg:w-7 max-lg:text-[17px]">
                      1
                    </span>
                    <h6 className="text-[28px] font-semibold leading-tight text-primary-700 max-lg:text-[18px]">
                      Lazy Guru' yu İndir
                    </h6>
                  </div>
                  <div className="flex flex-col items-center gap-6">
                    <div className="flex items-center gap-1 lg:flex-col lg:gap-4">
                      <DownloadIllustration className="w-[175px] shrink-0 max-lg:w-[75px]" />
                      <p className="text-[22px] font-medium leading-tight text-primary-700 max-lg:text-[16px] lg:text-center">
                        {IsMobile()
                          ? `"İndir!" butonuna tıklayarak mağazadan uygulamayı indirebilirsiniz.`
                          : `QR kodu okutarak mağazadan uygulamayı indirebilirsiniz.`}
                      </p>
                    </div>
                    <div className="rounded-md bg-neutral-100 p-2 max-lg:hidden">
                      <QrCode value="humanas.io/LazyGuruStore.php" />
                    </div>
                    <Button
                      onClick={handleCopyUniqueId}
                      className="w-full lg:hidden lg:bg-neutral-100 lg:text-primary-600"
                    >
                      İndir!
                    </Button>
                  </div>
                </div>
              )}
              {/* MATCH CARD */}
              <div className="flex flex-col rounded-xl bg-secondary_applicant-100 p-9 max-lg:p-4">
                <div className="mb-9 flex items-center gap-3 border-b border-neutral-300 pb-9 max-lg:mb-4 max-lg:pb-2">
                  {!state?.linkFound && (
                    <span className="flex h-10 w-10 items-center justify-center rounded-full bg-secondary_applicant-700 p-3.5 text-[26px] font-bold leading-tight text-neutral-100 max-lg:h-7 max-lg:w-7 max-lg:text-[17px]">
                      2
                    </span>
                  )}
                  <h6 className="text-[28px] font-semibold leading-tight text-secondary_applicant-700 max-lg:text-[18px]">
                    Hesabını Eşle
                  </h6>
                </div>
                <div className="flex flex-col items-center gap-6">
                  <div className="flex items-center gap-1 lg:flex-col lg:gap-4">
                    <SyncIllustration className="w-[175px] shrink-0 max-lg:w-[75px]" />
                    <p className="text-[22px] font-medium leading-tight text-secondary_applicant-700 max-lg:text-[16px] lg:text-center">
                      {IsMobile()
                        ? `"Eşle!" butonuna tıklayarak hesabınızı eşleyebilirsiniz.`
                        : `QR kodu okutarak hesabınızı eşleyebilirsiniz.`}
                    </p>
                  </div>

                  <div className="rounded-md bg-neutral-100 p-2 max-lg:hidden">
                    <QrCode value={campaign.campaignData.unique_id} />
                  </div>
                  <Link
                    className="w-full"
                    target="_blank"
                    to={campaign.campaignData.game_link}
                  >
                    <Button className="w-full max-lg:border-secondary_applicant-700 max-lg:bg-secondary_applicant-700 lg:hidden lg:bg-neutral-100 lg:text-primary-600">
                      Eşle!
                    </Button>
                  </Link>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="flex flex-col rounded-xl bg-primary-75 p-9 max-lg:p-4">
                <div className="mb-9  flex items-center gap-3 border-b border-neutral-300 pb-9 max-lg:mb-4 max-lg:pb-2">
                  <h6 className="text-[28px] font-semibold leading-tight text-primary-700 max-lg:text-[18px]">
                    Mobil Davet'e Git
                  </h6>
                </div>
                <div className="flex flex-col items-center gap-6">
                  <div className="flex items-center gap-1 lg:flex-col lg:gap-4">
                    <DownloadIllustration className="w-[175px] shrink-0 max-lg:w-[75px]" />
                    <p className="text-[22px] font-medium leading-tight text-primary-700 max-lg:text-[16px] lg:text-center">
                      QR kodu okutarak mobil davetiyeye yönlenebilirsiniz.
                    </p>
                  </div>
                  <div className="rounded-md bg-neutral-100 p-2 max-lg:hidden">
                    <QrCode value={window.location.href} />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const QrCode = ({ value }) => {
  return <QRCodeSVG size={165} value={value} />;
};

export default SharePage;
