import React from "react";

const CircularProgressBar = ({ value, small }) => {
  return (
    <div
      className={`relative ${
        small
          ? "h-[46px] w-[46px] max-lg:h-[36px] max-lg:w-[36px]"
          : "h-[70px] w-[70px] max-lg:h-[50px] max-lg:w-[50px]"
      } `}
    >
      <svg className="h-full w-full" viewBox="0 0 100 100">
        {/* <!-- Background circle --> */}
        <circle
          className="stroke-[#106BAC]"
          strokeOpacity={0.2}
          strokeWidth="8"
          cx="50"
          cy="50"
          r="40"
          fill="transparent"
        ></circle>
        {/* <!-- Progress circle --> */}
        <circle
          className="progress-ring__circle"
          strokeWidth="8"
          strokeLinecap="round"
          stroke={`url(#circular_progress)`}
          cx="50"
          cy="50"
          r="40"
          fill="transparent"
          strokeDashoffset={`calc(400 - (250 * ${value}) / 100)`}
        ></circle>

        {/* <!-- Center text --> */}
        <text
          x="50"
          y="53"
          textAnchor="middle"
          className="text-[30px] font-semibold text-primary-400"
          fill="currentColor"
          alignmentBaseline="middle"
        >
          {value}
        </text>
        <defs>
          <defs>
            <linearGradient
              id={`circular_progress`}
              x1="40.4238"
              y1="0.152954"
              x2="40.4238"
              y2="80.153"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#5266DC" />
              <stop offset="1" stopColor="#0DC9B1" />
            </linearGradient>
          </defs>
        </defs>
      </svg>
    </div>
  );
};

export default CircularProgressBar;
